import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { getContentfulUrl } from '../shared/utilities'
import richTextOptions from '../shared/richTextOptions'
import amazonButton from '../images/amazon-button.png'

const Book = ({ pageContext, location }) => {
  const {
    id,
    title,
    subTitle,
    author,
    pages,
    fileForDownload,
    bookCoverImage,
    createdAt,
    summary,
    publishDate,
    publisher,
    purchaseUrl
  } = pageContext

  const imgQuery = '?w=400'
  const imgUrl = bookCoverImage ? getContentfulUrl(bookCoverImage) + imgQuery : ''
  const downloadUrl = fileForDownload ? getContentfulUrl(fileForDownload) : ''

  return (
    <Layout path={location.pathname}>
      <SEO title={title} />
      <div className="container book text-content">
        <Link to="/resources/book-summaries/" className="back-link">
          &larr; Book Summaries
        </Link>

        <div className="row">
          <div className="col-md-5">
            <img style={{ maxWidth: '100%' }} src={imgUrl} alt={title} />
          </div>
          <div className="col-md-7" style={{ paddingLeft: '20px' }}>
            <h1>{title}</h1>
            <h3>{subTitle}</h3>
            <h4>{author}</h4>
            {documentToReactComponents(summary.json, richTextOptions)}
            <a href={purchaseUrl} id="LoginWithAmazon">
              <img border="0" alt="Order at Amazon" src={amazonButton} width="156" />
            </a>
            <br />
            <br />

            {fileForDownload && (
              <a
                href={downloadUrl}
                className="btn btn-secondary"
                style={{ padding: '10px', textTransform: 'none' }}
              >
                <FontAwesomeIcon icon={faDownload} /> Download Summary (PDF)
              </a>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Book
